<template>
    <div class="authwrapper">
        <b-overlay :show="show_overlay" rounded="sm">
            <b-card header="Reset Password" class="lghead">
                <div class="p-2 pr-3 pl-3 pb-3">
                    <!-- email -->
                    <b-form-group class="mt-3" label="Email">
                        <b-input-group>
                            <b-input-group-prepend is-text>
                                <feather-icon icon="MailIcon" />
                            </b-input-group-prepend>
                            <b-form-input v-model="email" type="email" placeholder="Email" v-bind:[emailState]="emailValidation" />
                        </b-input-group>
                        <b-form-invalid-feedback v-bind:[emailState]="emailValidation"> email format error </b-form-invalid-feedback>
                    </b-form-group>

                    <!-- password -->
                    <b-form-group label="New Password">
                        <b-input-group>
                            <b-input-group-prepend is-text>
                                <feather-icon icon="LockIcon" />
                            </b-input-group-prepend>
                            <b-form-input v-model="password" type="password" v-bind:[passWordState]="passWordValidation" placeholder="Password" />
                        </b-input-group>
                        <b-form-invalid-feedback v-bind:[passWordState]="passWordValidation">
                            must contain number and letter,length 6-20.
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <!-- confirm password   -->
                    <b-form-group>
                        <b-input-group>
                            <b-input-group-prepend is-text>
                                <feather-icon icon="LockIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                                v-model="password_confirm"
                                type="password"
                                v-bind:[passWordConfirmState]="passWordSameValidation"
                                placeholder="Confirm Password"
                            />
                        </b-input-group>
                        <b-form-invalid-feedback v-bind:[passWordConfirmState]="passWordSameValidation">
                            Password must be the same
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <!-- vcode   -->
                    <b-form-group label="Email Verification">
                        <b-input-group>
                            <b-input-group-prepend is-text>
                                <feather-icon icon="MessageCircleIcon" />
                            </b-input-group-prepend>
                            <b-form-input v-model="vcode" type="text" placeholder="input your v-code" />
                            <b-input-group-append>
                                <b-overlay :show="vsecs <= 0 ? false : true">
                                    <b-button variant="secondary" @click="send_vcode"> Send Me V-code </b-button>
                                    <template #overlay>
                                        <b-spinner small class="mr-3 mt-3" label="Small Spinner" />
                                        {{ vsecs }}s
                                    </template>
                                </b-overlay>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                    <!-- submit and reset -->
                    <b-button type="submit" variant="primary" class="btn-block mt-4" @click="resetPassword"> Reset </b-button>

                    <b-card-text class="mt-3 text-center mb-3">
                        <img src="/favicon.ico" width="15px" height="15px" style="border-radius: 5px; margin-bottom: 2px" class="mr-1" /> Already have
                        an account?
                        <a class="goto_login" @click="goto_login">Login</a>
                    </b-card-text>
                </div>
            </b-card>
        </b-overlay>
    </div>
</template>

<script>
import {
    BCard,
    BCardTitle,
    BCardText,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInvalidFeedback,
    BAlert,
    BFormCheckbox,
    BButton,
    BImg,
    BOverlay,
} from "bootstrap-vue";

export default {
    components: {
        BCard,
        BCardTitle,
        BCardText,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BInputGroupAppend,
        BFormInvalidFeedback,
        BAlert,
        BFormCheckbox,
        BButton,
        BImg,
        BOverlay,
    },

    data() {
        return {
            password_confirm: "",
            password: "",
            email: "",
            show_overlay: false,
            vsecs: 0,
            vcode: "",
        };
    },
    computed: {
        passWordState() {
            return this.password.length != 0 ? "state" : null;
        },
        passWordValidation() {
            return this.$tools.validator.validatePassword(this.password);
        },

        passWordConfirmState() {
            return this.password_confirm.length != 0 ? "state" : null;
        },
        passWordSameValidation() {
            return this.password == this.password_confirm;
        },

        emailState() {
            return this.email.length != 0 ? "state" : null;
        },
        emailValidation() {
            return this.$tools.validator.validateEmail(this.email);
        },
    },
    methods: {
        goto_login() {
            this.$router.push({ path: "/login" });
        },

        async send_vcode() {
            if (!this.emailValidation) {
                this.$bvToast.toast("email format error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.vsecs = 30;
            var x = setInterval(() => {
                this.vsecs--;
                if (this.vsecs < 0) {
                    clearInterval(x);
                }
            }, 1000);

            let resp = await this.$api.user.getEmailVCode(this.email);
            if (resp.result.meta_status <= 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            } else {
                this.$bvToast.toast("vcode send", {
                    title: `Success`,
                    variant: "success",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }
        },

        async resetPassword() {
            if (!this.emailValidation) {
                this.$bvToast.toast("email format error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (!this.passWordValidation || !this.passWordSameValidation) {
                this.$bvToast.toast("password error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let resp = await this.$api.user.resetPassword(this.email, this.password, this.vcode);
            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("password reset success, please relogin", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });

            this.$store.commit("auth/UPDATE_MY_LOGIN_ROLES_PERMISSIONS", {
                web_token: false,
                roles: [],
                permissions: [],
            });

            window.location = "/login"; //use window.location to redirect as javascript cache is cleared
            return;
        },
    },
};
</script>
